@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,700italic,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,300italic,400italic,700,700italic);

@import "fa/font-awesome";

@import "common/_global";
@import "common/_plugins";
@import "components/_buttons";
@import "components/_forms";
@import "components/_grid";
@import "layouts/_header";
@import "layouts/_footer";
@import "layouts/_pages";
