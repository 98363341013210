.gbl-btn-blue {
	background-color:#073766;
	border-radius: 8px;
	color:#fff;
	font-size:20px;	
	padding-left:0.90em;
	padding-right:0.90em;
	&:hover {
		opacity: 0.8;
	}
}