header.site-header {
	padding:0.75em 0em;
	.masthead-brand {
		float:left;
		margin:0;
		img {
			display: block;
		}
		
	}
	.masthead-nav {
		float:right;
		list-style-type: none;
		padding-left:none;
		padding-top:1.60em;
		li {
			display:inline-block;
			padding-left:10px;

			a {
				font-size:1.15em;
				text-decoration: none;
				// line-height: 5em;
				color:#444444;
				font-weight:700;
				&:hover {
					text-decoration: none;
					background-color: transparent;
					color:#073766;
				}
			}
			&.nav-login-btn {
				a {
					color:#fff;
					background-color:#073766;
					border-radius: 8px;
					padding-left:20px;
					padding-right:20px;
					&:hover {
						background-color:#073766;
						opacity: 0.8;
						color:#fff;

					}
				}
			}
			&.nav-signup-btn {
				a {
					color:#fff;
					background-color:#00bbd3;
					border-radius: 8px;
					padding-left:20px;
					padding-right:20px;
					&:hover {
						background-color:#00bbd3;
						opacity: 0.8;
						color:#fff;
					}
				}
			}
		}
		
	}
}