.site-content {
	//sub-header
	.page-sub-header {
		background-image:url(../images/subheader-bg.jpg);
		background-size:cover;
		background-position: center center;
		height:260px;
		h1 {
			font-size: 3em;
			text-transform:uppercase;
			line-height: 260px;
			padding:0;
			margin:0;
			color:#073766;
			text-shadow:0px 0px 10px rgba(255,255,255,0.8);
		}
	}
	//home
	.hs-slider-wrap {
		#home-slider {
			.sy-slide {
				.text-content {
					position: absolute;
					top:30%;
					padding:1em;
					max-width: 50%;
					height: 300px;
				}
				.place-right {
					right:20%;
					h4 {
						font-size:38px;
						color:#fff;
						font-family:'Roboto';
						text-shadow:0px 0px 10px rgba(0,0,0,0.8);
					}
					.button-link {
						background-color: #00bbd3;
						color:#fff;
						font-size:1.5em;
						&:hover,
						&:focust {
							background-color:#00bbd3;
							opacity: 0.8;
							color:#fff;
						}
					}
				}
				.place-left {
					left:20%;
					h4 {
						
						color:#000;
						font-family:'Roboto';
						text-shadow:0px 0px 10px rgba(255,255,255,0.8);
						.larger-text {
							font-size:34px;
						}
						.smaller-text {
							font-size:28px;
						}
					}
					.button-link {
						background-color: #073766;
						color:#fff;
						font-size:1.5em;
						&:hover,
						&:focust {
							background-color:#073766;
							opacity: 0.8;
							color:#fff;
						}
					}
				}
				.image-content {
					line-height: auto;
					img {
						max-width: 100%;
						width: 100%;
					}
				}
			}
		}
	}
	//solutions
	.solutions-cta-wrap {
		.scta-inner {
			padding:3em 0em;
			.blue-btn {
				background-color:#073766;
				border-radius: 8px;
				color:#fff;
				font-size:20px;	
				padding-left:0.90em;
				padding-right:0.90em;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.why-c2-wrap {
		background-color:#ececec;
		padding:3em 0em;
		.why-header {
			h3 {
				padding-bottom:1em;
				font-size:2.25em;
			}
		}
		.fi-single {
			padding-bottom:1.5em;
			padding-top:1em;
			//display: inline-block;
			// float:none;
			.fi-image {
				max-width: 100%;
			}
			.fi-content {
				padding-top:1.5em;
				p {
					color:#083766;
				}
			}
		}
	}
	.how-it-works-wrap {
		background-color:#f1f7fb;
		padding:3em 0em;
		.how-header {
			h3 {
				padding-bottom:1em;
				font-size:2.25em;
				color:#0e0e0e;
			}
		}
		.hiw-tabs {
			.nav-tabs {
				a {
					color:#070707;
					font-size: 1.85em;
					padding-right:1em;
					padding-left:1em;
					
				}
				.active {
					background-color:#fff;

				}
			}
			.tab-content {
				border-style:solid;
				border-color:#ddd;
				border-width:0px 1px 1px 1px;
				.tab-pane {
					padding:1.5em;
					background-color: #fff;
					p {
						padding-top:1em;
						font-size: 1em;
						.step-no {
							color:#073766;
						}
						.step-title {
							font-size:26px;
						}
					}
				}

			}
		}
		.hiw-note {
			padding-top:1em;
			p {
				font-size: 0.85em;
			}
		}
	}
	//contact 
	.cf-wrap {
		padding:3em 0em;
		.cf-header {
			padding-bottom:1.5em;
			h3 {
				font-size:1.75em;
				color:#073766;
			}
		}
		form {
			input[type="text"],
			input[type="email"],
			textarea {
				padding:1.5	em;
				width: 100%;
			}
			textarea {
				resize: none;
				min-height:200px;
			}

		}
		.cd-data {
			padding-top:6em;
		}
	}
}